export const environment = {
  production: false,
  oauthBase: 'https://login-stage.inmarsat.com',
  oauthUrl: '/oauth2/ausm8g8wZzBpBdp0N0x5/v1/authorize',
  logoutUrl: '/oauth2/ausm8g8wZzBpBdp0N0x5/v1/logout',
  oauthClientId: '0oa4kkwj0C14FR69r0x6',
  redirectUrl: 'https://cms-ui.stg2.sd.a.inmarsat.global',
  name: 'stg2',
  version: '1.0.0',
  translationPrefix: './assets/i18n/',
  STATIC_CONTENT_URL: './assets/i18n/en.json',
  api: {
    baseUrl: 'https://contract-cms-producer-relationship-stg2.integration-nonprod.inmarsat.global',
    uploadUrl: '/file/uploadFile',
    updateUrl: '/file/updateContract',
    getCustomerContractSuggestions: '/api/getContractIdsAndCustomerIds',
    getContracts: '/api/getContractIds',
    getContractDetails: '/api/getContractDetails',
    getContractDataAsJson: '/api/exportContractDataAsJson',
    clearContractData: '/api/deleteMigrationData',
    modifyContractData: '/api/modifyContractDetails',
    pricingScheduleInfo: '/api/getPricingSchedInfo',
    versionHistory: '/api/getContractDetailsFromAuditLog',
    createNewContract: '/api/createContractFromGUI',
    getAuditLog: '/api/exportAuditLog',
    modifyPredefinedValues: '/api/modifyPredefinedValues',
    customerId: '?customerId=',
    customerName: '?cleName=',
    contractType: '&contractType=',
    predefinedContractType: '?contractType=',
    contractId: '?contractId=',
    user: '?user=',
    exclusionUser: '&user=',
    uploadType: '&uploadType=',
    contractConstant: '/api/getPredefinedValues',
    getCLEList: '/api/getCleList',
    addNewCLEExclusion: '/api/addCle',
    deleteCLEIdExclusion: '/api/deleteCle',
    userManagementBase: 'https://api-sandbox.inmarsat.global/v1/userManagement',
    access: '/access',
    profileUrl: '/access/profile'
  }
};
